import React from "react";
import AppPage from "../components/AppPage";
import CertificationContent from "../components/certification/CertificationContent";
const Certification = () => {
  return (
    <AppPage>
      <CertificationContent />
    </AppPage>
  );
};
export default Certification;
