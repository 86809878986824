import React, { useEffect } from "react";
import Pdf from "react-to-pdf";
import "../../assets/fonts/Allison-Regular.ttf";
import "../../assets/styles/Certification.css";
import logo1 from "../../assets/images/logo1a.png";
import logo2 from "../../assets/images/logo2a.png";
import backgroundImage from "../../assets/images/pdfbackground.png";
import mainlogo from "../../assets/images/R_R-logo2-02.png";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setDate, setUsername } from "../../reducers/certificationSlice";
const CertificationContent = () => {
  const userInfo = useSelector((state) => state.certification.username);
  const date = useSelector((state) => state.certification.date);
  const dispatch = useDispatch();
  const dateInfo = date ? moment(date.split("T")[0]).format("D/MM/YYYY") : "-";
  const lastIndex = userInfo.lastIndexOf(" ");
  const fullName = userInfo.substring(0, lastIndex);
  const ref = React.createRef();
  const buttonRef = React.createRef();
  useEffect(() => {
    buttonRef.current.click();
  });
  const finishedPrint = async () => {
    setTimeout(() => {
      dispatch(setUsername(null));
      dispatch(setDate(null));
      window.close();
    }, 0);
  };
  const options = {
    orientation: "landscape",
    format: "letter",
  };
  console.log('date',dateInfo)
  return (
    <div
      className="
justify-center bg-app-card
min-h-[816px] justify-items-center
flex min-w-[1054px]"
    >
      <Pdf
        targetRef={ref}
        filename="certificate.pdf"
        options={options}
        onComplete={finishedPrint}
      >
        {({ toPdf }) => (
          <button
            onClick={async (e) => {
              await toPdf(e);
            }}
            ref={buttonRef}
          >
            Generate Pdf
          </button>
        )}
      </Pdf>
      <div ref={ref} className="absolute top-0 h-[816px] w-[1054px] bg-white ">
        <div className="grid grid-cols-12 pr-6 pl-6 pb-10 h-full">
          <div className="col-span-8 h-full justify-center flex">
            <div className="w-[85%] text-center mt-20">
              <h1 className=" font-face-ar text-8xl text-app-certificate-accent">
                Certificate of Completion
              </h1>
              <div className="w-[90%] mx-auto justify-center">
                <p className="text-xl mt-2 text-black font-normal">has been proudly presented to</p>
                <p className="text-2xl mt-16 mb-4 text-app-certificate-accent">
                  {fullName}
                </p>
                <p className="text-xl mt-12 mb-8 text-black">
                  For successfully understanding and completing the R+R®
                  education modules and helping create a world we all want to live
                  in.
                </p>
                <div className="col-span-8 h-full justify-center flex">
                  <div className="w-[90%] mx-auto justify-center">
                    <p className="text-xl text-app-certificate-accent">
                     Completion Date  {dateInfo}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex mr-8">
                <img className="mr-8" src={logo2} width={150} alt="logo-left"></img>
                <div className="flex flex-col items-center justify-center">
                  <h1 className=" font-face-ar text-5xl text-app-certificate-accent text-black mt-10 mb-6">
                    Congratulations
                  </h1>
                <img
                  src={logo1}
                  width="440px"
                  height="80px"
                  alt="logo-right"
                ></img>
                </div>
              </div>
            </div>
          </div>
          <div
            className="
      col-span-4
      flex items-start h-full justify-center items-center" style={{backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}
          >
            {mainlogo ? (
                <img
                  className=" -mr-10 -rotate-90"
                  width={250}
                  color="white"
                  fill="white"
                  src={mainlogo}
                  alt="Savor Box"
                />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificationContent;
